import type { DataTableSortEvent } from 'primevue/datatable'
import type { Ref } from 'vue'

import { captureException } from '@sentry/vue'
import { isAxiosError } from 'axios'
import { toast as sonner } from 'vue-sonner'

export function useServerErrorHandler() {
  const { t } = useI18n()
  function onError(error: any, lazyEvent?: Ref<DataTableSortEvent | null>, filters?: Ref<Record<string, any>>, violationsPath: string = 'violations.', hideToast: boolean = false): string {
    let detail = t('common.server.unknown_error')
    console.log('typeof', typeof error)
    console.log('isAxiosError', isAxiosError(error))
    console.log('error1:', error)
    if (
      isAxiosError(error)
      && error.response?.data?.detail
    ) {
      console.log(error.response.data.detail)

      detail = error.response.data.detail
    }

    if (
      isAxiosError(error)
      && error.response?.data?.violations
    ) {
      let violationsDetails = ''
      for (const violation of error.response.data.violations) {
        const numbers: number[] = []
        const output = violation.propertyPath.replace(/\[(\d+)\]/g, (_: any, p1: string) => {
          numbers.push(Number(p1))
          return ''
        })
        const firstNumber = numbers ? numbers[0] ?? 0 : 0
        const propertyPathSplided = output.split('.')
        let propertyPath = ''
        let lastKey = ''
        propertyPathSplided.forEach((path: string, index: number) => {
          const key = t(`${violationsPath}.${lastKey + path}._label`, { count: firstNumber })
          const computedKey = key.includes('_label') ? path : key
          propertyPath += `${computedKey}${index === propertyPathSplided.length - 1 ? '' : '->'}`
          lastKey = `${lastKey + path}.`
        })
        violationsDetails += `[${propertyPath}]: ${violation.message}\n`
      }
      detail = violationsDetails
    }

    if (detail === t('server.unknown_error') && isAxiosError(error) && error.response?.status) {
      detail = t(`common.server.${error.response?.status}`)
    }

    if (!hideToast) {
      sonner.error(detail, {
        duration: 3000,
      })
    }
    captureException(error, (scope) => {
      scope.setTag('location', 'table.helpers.ts')
      const context: {
        lazyEvent?: DataTableSortEvent | null
        filters?: Record<string, any>
      } = {}
      if (lazyEvent?.value)
        context.lazyEvent = lazyEvent.value

      if (filters?.value)
        context.filters = filters.value

      scope.setContext('data', context)
      return scope
    })
    return detail
  }
  return {
    onError,
  }
}
