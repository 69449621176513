import type { ComputedRef } from 'vue'
import type { HydraCollection } from '../../../../common'
import type {
  CaseFileHydraItem,
  CaseFileListHydraCollectionitem,
  ClientHydraItem,
  CollectionCostGroupsHydraItem,
  IndividualHydraItem,
  OrganizationHydraItem,
} from '../../../../composables'
import { AxiosError } from 'axios'
import { PermissionAttributes } from '../../../../composables'
import { useCaseFileEditPermissions } from './useCaseFileEditPermissions.ts'
import { useCaseFileSettlementHelper } from './useCaseFileSettlementHelper.ts'
import { useCaseFileTotals } from './useCaseFileTotals.ts'

export const indexMapping: Record<number, string> = {
  0: 'tasks',
  1: 'log',
  2: 'correspondence',
  3: 'notes',
  4: 'files',
  5: 'creditor_invoices',
  6: 'debtor_invoices',
  7: 'debtor_costs',
  8: 'payments',
  9: 'third_parties',
  10: 'payment_plan_groups',
  11: 'payouts',
}

function getIndexByKey(key: string): number {
  const id = Object.entries(indexMapping).find(([_, value]) => value === key)?.[0]
  return id ? Number.parseInt(id) : 0
}

const [useProvideCaseFileEdit, useCaseFileEditRaw] = createInjectionState((id: ComputedRef<string | undefined>) => {
  const { addRecent } = useRecentStore()
  const accessDenied = ref<'case_file' | 'creditor' | 'debtor' | 'collection_cost_group' | null>(null)
  const isAccessDenied = computed(() => !!accessDenied.value)
  const data = ref<CaseFileHydraItem | null>(
    null,
  )
  const creditor = ref<ClientHydraItem | null>(null)
  const debtor = ref<OrganizationHydraItem | IndividualHydraItem | null>(null)
  const collectionCostGroup = ref<CollectionCostGroupsHydraItem | null>(null)
  const { hasPermission } = usePermissions()
  const caseFileLists = ref<CaseFileListHydraCollectionitem[]>([])
  const { onError } = useServerErrorHandler()
  const loading = ref(true)
  const loadingCaseFileLists = ref(true)
  const error = ref<string | null>(null)

  const onUpdateCaseFileLists = async () => {
    if (!data.value) {
      return
    }
    if (hasPermission(
      PermissionAttributes.CONTEXT.OPERATION.CASE_FILE_LIST.READ_COLLECTION_VIA_CASE_FILE,
      {
        CLIENT: creditor.value?.['@id'],
      },
    )) {
      const { data: caseFileListsData } = await api.get<HydraCollection<CaseFileListHydraCollectionitem>>(`/api/case_files/${data.value.id}/lists`)
      caseFileLists.value = caseFileListsData.member
      data.value.caseFileLists = caseFileLists.value.map(list => list['@id'])
    }
  }
  async function loadCaseFile(load = true) {
    try {
      error.value = null
      if (load)
        loading.value = true

      accessDenied.value = null

      const res = await api
        .get<CaseFileHydraItem>(`/api/case_files/${id.value}`)

      const [creditorRes, debtorRes, collectionCostGroupRes] = await Promise.all([
        api.get<ClientHydraItem>(res.data.creditor),
        api.get<IndividualHydraItem | OrganizationHydraItem>(res.data.debtor),
        res.data.collectionCostGroup ? api.get<CollectionCostGroupsHydraItem>(res.data.collectionCostGroup) : Promise.resolve({ data: null }),
      ])

      creditor.value = creditorRes.data
      debtor.value = debtorRes.data
      if (collectionCostGroupRes.data) {
        collectionCostGroup.value = collectionCostGroupRes.data
      }

      data.value = res.data
      addRecent({
        type: 'casefile',
        id: id.value as string,
        name: `${creditor.value?.name}`,
      })
    }
    catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 404) {
          return
        }
        if (err.response?.status === 403) {
          if (err.config?.url?.includes('/api/case_files/')) {
            accessDenied.value = 'case_file'
          }
          else if (err.config?.url?.includes('/api/clients/')) {
            accessDenied.value = 'creditor'
          }
          else if (err.config?.url?.includes('/api/individuals/') || err.config?.url?.includes('/api/organizations/')) {
            accessDenied.value = 'debtor'
          }
          else if (err.config?.url?.includes('/api/collection_cost_groups/')) {
            accessDenied.value = 'collection_cost_group'
          }
          return
        }
      }
      error.value = onError(err)
    }
    finally {
      loading.value = false
    }
  }
  const initLoadCaseFiles = async () => {
    try {
      loadingCaseFileLists.value = true
      await onUpdateCaseFileLists()
    }
    catch (err) {
      onError(err)
    }
    finally {
      loadingCaseFileLists.value = false
    }
  }

  const { reopenCaseFile } = useCaseFileReOpen(() => loadCaseFile().then(), id)

  const refreshDebtor = async (newDebtor?: IndividualHydraItem | OrganizationHydraItem | null) => {
    if (newDebtor) {
      debtor.value = newDebtor
    }
    else if (debtor.value) {
      const iri = debtor.value['@id']
      const { data: debtorData } = await api.get<IndividualHydraItem | OrganizationHydraItem>(iri)
      Object.assign(debtor.value, debtorData)
    }
    else {
      await loadCaseFile()
    }
  }

  watch(() => id.value, () => {
    loadCaseFile().then(() => {
      initLoadCaseFiles().then()
    })
  }, {
    immediate: true,
  })
  function updateCaseFile(item: CaseFileHydraItem) {
    console.log('updateCaseFile', item)
    data.value = item
  }

  const isClosed = computed(() => !!data.value?.closeReason)
  // const { totals, loading: loadingTotals, getCaseFileTotals }
  const { loading: loadingTotals, ...totalsHook } = useCaseFileTotals(id)

  const useCaseFileEditPermissionsHook = useCaseFileEditPermissions({
    creditor,
  })
  const { mappedActiveTabs } = useCaseFileEditPermissionsHook
  const hasSetInitialTab = ref(false)
  const route = useRoute()
  const index = ref(route.query.tab ? getIndexByKey(route.query.tab as string) : 0)
  // const index = ref(5'')
  const { replace } = useRouter()
  watch(() => index.value, (value) => {
    replace({ query: { tab: indexMapping[value] } }).then()
  }, {
    immediate: true,
  })

  watch([
    () => mappedActiveTabs.value,
    () => loading.value,
  ], () => {
    if (loading.value) {
      return
    }
    if (!hasSetInitialTab.value) {
      if (route.query.tab) {
        const preferredIndex = getIndexByKey(route.query.tab as string)
        if (mappedActiveTabs.value[preferredIndex]) {
          index.value = preferredIndex
          return
        }
      }
      const activeTabs = Object.entries(mappedActiveTabs.value).find(([_, value]) => value)
      index.value = activeTabs ? Number.parseInt(activeTabs[0]) : index.value
    }
    else {
      hasSetInitialTab.value = true
    }
  }, {
    immediate: true,
  })
  const settlement = useCaseFileSettlementHelper(id)

  const hasAnyTabPermission = computed(() => Object.values(mappedActiveTabs.value).some(Boolean))

  return {
    data,
    index,
    ...totalsHook,
    ...settlement,
    ...useCaseFileEditPermissionsHook,
    loadingTotals,
    accessDenied,
    isClosed,
    reopenCaseFile,
    creditor,
    debtor,
    collectionCostGroup,
    loadingCaseFileLists,
    caseFileLists,
    refreshDebtor,
    onUpdateCaseFileLists,
    updateCaseFile,
    loadCaseFile,
    isAccessDenied,
    loading,
    error,
    hasAnyTabPermission, // Add this new computed property to the returned object
  }
})

export { useProvideCaseFileEdit }
// If you want to hide `useCaseFileEdit` and wrap it in default value logic or throw error logic, please don't export `useCaseFileEdit`

export function useCaseFileEdit() {
  const store = useCaseFileEditRaw()
  if (store == null)
    throw new Error('Please call `useProvideCaseFileEdit` on the appropriate parent component')
  return store
}
