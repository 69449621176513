import type { HydraCollection } from '../../common'

export enum BaseUrl {
  LOGIN_RESOLVED = 'https://login.resolved.dev',
  LOGIN_DEBTT = 'https://login.debtt.dev',
  LOGIN_COLLECT4U = 'https://login.collect4u.dev',
  API = 'https://api.resolved.dev',
}

export async function getAll<T = any>({
  url,
  initialParams,
  perPage = 100,
  signal,
}: {
  url: string
  initialParams?: URLSearchParams
  perPage?: number
  signal?: AbortSignal
}): Promise<T[]> {
  let items: T[] = []

  async function fetch(page = 1) {
    if (signal?.aborted) {
      return
    }
    const params = new URLSearchParams(initialParams)
    params.set('perPage', perPage.toString())
    params.set('page', page.toString())

    const data = await api
      .get<HydraCollection<T>>(url, { params, signal })
      .then(({ data }) => data.member)
    items = [...items, ...data]

    if (data.length === perPage)
      await fetch(page + 1)

    return items
  }

  await fetch()

  return items
}
