import type { ComputedRef, Ref } from 'vue'
import type { HydraCollection } from '../../../common'
import type { CorrespondenceTemplateContentIdentifierHydraItem } from '../correspondences'
import type {
  EntityReferenceValueObjectSchema,
  FixedValueObjectSchema,
  StringTemplateObjectSchema,
} from './documents.schema.ts'
import type { EmbeddedPartDocumentSchema } from './embeddedPartDocuments.schema.ts'
// useSourceViewer.ts
import { createInjectionState } from '@vueuse/shared'
import dayjs from 'dayjs'
import { findCidValues } from '../../utils/findCidValues.ts'
import { AbstractValueTypeEnum } from './documents.interface.ts'

export interface CorrespondenceTemplateContentIdentifierHydraItemWithUrl extends CorrespondenceTemplateContentIdentifierHydraItem {
  url?: string
}

const [useProvideDocumentContentIdentifiers, useDocumentContentIdentifiersRaw]
  = createInjectionState(
    ({
      body,
      contentIdentifiers,
      values,
      updateContentIdentifiers,
      updateValues,
      afterInitialLoad,
      getInitialContentIdentifiersFromHtml,
    }: {
      contentIdentifiers: ComputedRef<CorrespondenceTemplateContentIdentifierHydraItemWithUrl[]> | Ref<CorrespondenceTemplateContentIdentifierHydraItemWithUrl[]>
      updateContentIdentifiers: (contentIdentifiers: CorrespondenceTemplateContentIdentifierHydraItemWithUrl[]) => void
      body: Ref<StringTemplateObjectSchema> | Ref<StringTemplateObjectSchema | undefined> | ComputedRef<StringTemplateObjectSchema | undefined> | ComputedRef<StringTemplateObjectSchema>
      values:
        | ComputedRef<EmbeddedPartDocumentSchema[]>
        | Ref<EmbeddedPartDocumentSchema[]>
      updateValues: (values: EmbeddedPartDocumentSchema[]) => void
      afterInitialLoad?: () => void
      getInitialContentIdentifiersFromHtml?: boolean
    }) => {
      // state

      const documentTypeFormatters = computed(() => {
        return {
          [AbstractValueTypeEnum.FixedValue]: async (
            document: FixedValueObjectSchema,
          ) => {
            console.log('[ContentIdentifiers]:', contentIdentifiers.value)
            return document.value
              ? (contentIdentifiers.value.find(
                  ci => ci.contentIdentifier === document.value,
                )?.name
                ?? 'Oeps!')
                || ''
              : ''
          },
        }
      })

      const contentIdentifiersOptions = computed<
        {
          label: string
          value: string
          disabled?: boolean
        }[]
      >(() =>
        contentIdentifiers.value.map(ci => ({
          label: ci.name,
          value: ci.contentIdentifier,
          disabled: values.value.some(
            v =>
              v.contentIdentifier?.['#type']
              === AbstractValueTypeEnum.FixedValue
              && (v.contentIdentifier as FixedValueObjectSchema).value
              === ci.contentIdentifier,
          ),
        })),
      )

      const templateIri = computed(() => {
        if (body.value?.template?.['#type'] === AbstractValueTypeEnum.EntityReference) {
          return (body.value.template as EntityReferenceValueObjectSchema)?.iri ?? ''
        }
        return ''
      })

      const setInitialOriginalContentIdentifiers = async () => {
        if (!templateIri.value) {
          updateContentIdentifiers([])
          return
        }
        console.log('templateIri:', templateIri.value)
        const { data } = await api.get<
          HydraCollection<CorrespondenceTemplateContentIdentifierHydraItem>
        >(
          `/api/correspondence_templates/${iriToId(templateIri.value)}/content_identifiers`,
        )
        updateContentIdentifiers(data.member.filter(v => !!v.contentIdentifier))
      }

      const resetContentIdentifiers = () => {
        if (values.value) {
          const oldValues = [...values.value]
          // for (const value of oldValues) {
          //   if (
          //     value.contentIdentifier?.['#type']
          //     === AbstractValueTypeEnum.FixedValue
          //   ) {
          //     const schema = value.contentIdentifier as FixedValueObjectSchema
          //     schema.value = ''
          //   }
          // }
          updateValues(oldValues.filter(v => v.contentIdentifier?.['#type'] !== AbstractValueTypeEnum.FixedValue))
        }
      }

      watch(
        () => templateIri.value,
        (v) => {
          console.log('templateIri changed', v)
          resetContentIdentifiers()
          setInitialOriginalContentIdentifiers().then()
        },
      )

      const hasTemplateValue = computed(() => {
        if (body.value?.template?.['#type'] === AbstractValueTypeEnum.EntityReference) {
          return !!(body.value.template as EntityReferenceValueObjectSchema).iri
        }
        if (body.value?.template?.['#type'] === AbstractValueTypeEnum.FixedValue) {
          return true
        }
        return false
      })

      const isEntityReference = computed(() => {
        return body.value?.template?.['#type'] === AbstractValueTypeEnum.EntityReference
      })

      const isFixedValue = computed(() => {
        return body.value?.template?.['#type'] === AbstractValueTypeEnum.FixedValue
      })
      const url = new URL('@assets/placeholder.jpeg', import.meta.url).href

      const initialCidValuesFixed = () => {
        if (body.value?.template?.['#type'] === AbstractValueTypeEnum.FixedValue && (body.value.template as FixedValueObjectSchema).value) {
          const htmlString = (body.value.template as FixedValueObjectSchema).value
          const cidValues = findCidValues(htmlString)
          updateContentIdentifiers(cidValues.map((cid) => {
            const id = Date.now()
            return {
              '@context': '/api/v1/contexts/CorrespondenceTemplateContentIdentifier',
              '@id': `/api/v1/correspondence_template_content_identifiers/${id}`,
              '@type': 'CorrespondenceTemplateContentIdentifier',
              id,
              'createdAt': dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              'updatedAt': dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              'locale': 'en',
              'name': `${cid}`,
              'contentIdentifier': cid,
              url,
            }
          }))
        }
      }

      onMounted(() => {
        setInitialOriginalContentIdentifiers().then(() => {
          afterInitialLoad?.()
          if (getInitialContentIdentifiersFromHtml) {
            initialCidValuesFixed()
          }
        })
      })

      return {
        contentIdentifiersOptions,
        contentIdentifiers,
        updateContentIdentifiers,
        hasTemplateValue,
        isEntityReference,
        isFixedValue,
        setInitialOriginalContentIdentifiers,
        documentTypeFormatters,
      }
    },
  )
export { useProvideDocumentContentIdentifiers }

// If you want to hide `useSourceViewer` and wrap it in default value logic or throw error logic, please don't export `useSourceViewer`
export function useDocumentContentIdentifiers() {
  const counterStore = useDocumentContentIdentifiersRaw()
  if (counterStore == null) {
    throw new Error(
      'Please call `useProvideDocumentContentIdentifiers` on the appropriate parent component',
    )
  }
  return counterStore
}
